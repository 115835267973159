import { useMediaQuery } from 'react-responsive';
import { BsArrowRight } from 'react-icons/bs';
import { GiSupersonicArrow } from 'react-icons/gi';
import { MdOutlineSpeed } from 'react-icons/md';
import { BiCustomize } from 'react-icons/bi';
import { CgSmileMouthOpen } from 'react-icons/cg';

import HighlightedText from '../../components/HighlightedText';
import Article from '../../components/Article';
import HighlightCard from '../../components/HighlightCard';
import Curve from '../../images/curve.png';
import Graph from '../../images/graph.png';

import styles from './styles.module.css';

const Home = () => {
  const useMobileComponent = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <div className={styles.home}>
      <div className={styles.landing}>
        <div className={styles.leftGroup}>
          <Article
            title={
              <div className={styles.title}>
                <HighlightedText
                  position="start"
                  color="secondary"
                  sizeMultiplier={1.5}
                  bottom={10}
                >
                  Eikonnex OCR
                </HighlightedText>
              </div>
            }
            body={<div className={styles.subtitle}>Your Thai OCR Solution</div>}
            textColor="light"
            divider={false}
            spacing={10}
          />
        </div>
        {!useMobileComponent && (
          <div className={styles.rightGroup}>
            <img className={styles.graph} src={Graph} alt="" />
          </div>
        )}
        <img className={styles.curve} src={Curve} alt="" />
      </div>
      <div className={styles.readMeHighlights}>
        <Article
          title="Eikonnex OCR Highlights"
          body={
            <>
              <div className={styles.highlightsCardContainer}>
                <HighlightCard
                  title={'Outstanding OCR Accuracy'}
                  body={
                    <ul>
                      <li>Very high English and Thai OCR accuracy</li>
                      <li>Text reader that actually works in production</li>
                    </ul>
                  }
                  footer={<GiSupersonicArrow size={30} />}
                />
                <HighlightCard
                  title={'Very Fast'}
                  body={
                    <ul>
                      <li>Exceptionally fast OCR processing speed</li>
                      <li>
                        Configurable and runnable on various processing unit
                        models
                      </li>
                    </ul>
                  }
                  footer={<MdOutlineSpeed size={30} />}
                />
                <HighlightCard
                  title={'Flexible Customizable'}
                  body={
                    <ul>
                      <li>Support both document and scene text reader</li>
                      <li>Customizable for various business domain contexts</li>
                    </ul>
                  }
                  footer={<BiCustomize size={30} />}
                />
                <HighlightCard
                  title={'Easy-to-use API'}
                  body={
                    <ul>
                      <li>
                        REST API to integrate OCR engine to your digital
                        transformation solution
                      </li>
                    </ul>
                  }
                  footer={<CgSmileMouthOpen size={30} />}
                />
              </div>
              <div className={styles.tryDemoContainer}>
                <a
                  href="https://ocr.eikonnex.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.demoButton}>
                    <span className={styles.demoButtonText}>Try Our Demo</span>{' '}
                    <BsArrowRight color="fe9200" size={20} />
                  </button>
                </a>
              </div>
            </>
          }
        />
      </div>
      <div className={styles.partners}>
        <Article
          title="Customers & Partners"
          body={
            <div className={styles.partnersLogo}>
              <img
                src={`${process.env.PUBLIC_URL}/images/ttb-logo.png`}
                alt="ttb logo"
                style={{ width: '125px' }}
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/ditto-logo.png`}
                alt="ditto logo"
                style={{ width: '215px' }}
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/beryl8-logo.png`}
                alt="beryl8 plus logo"
                style={{ width: '115px' }}
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/iet-logo.png`}
                alt="iet logo"
                style={{ width: '140px' }}
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/blueparking-logo.png`}
                alt="blue parking logo"
                style={{ width: '85px' }}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export const homePath = '/';

export default Home;
