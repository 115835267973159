import PageHeader from '../../components/PageHeader';
import Article from '../../components/Article';
import styles from './styles.module.css';
import DoubleGridRow from '../../components/DoubleGridRow';

const Solutions = () => {
  return (
    <div className={styles.solutions}>
      <PageHeader title="Solutions" />
      <div className={styles.aboutReadMe}>
        <Article
          title={
            <div className={styles.solutionsTitle}>About Eikonnex OCR</div>
          }
          divider={false}
          spacing={15}
          body={
            <>
              <span className={styles.primaryColorText}>Eikonnex OCR</span> is
              an accurate{' '}
              <span className={styles.secondaryColorText}>
                English and Thai OCR text reader
              </span>{' '}
              that is robust to many variations found in documents and scene
              images and videos. It enables developers and system integrators to
              quickly create applications that extract meaning from documents or
              images.
              <br />
              <br />
              Eikonnex OCR technology combines state of the art in image
              processing and adaptive learning representation method of deep
              learning to ensure highest text reading accuracy and performance
              while optimizing processing time and computational resources. In
              addition, Eikonnex OCR can achieve high accuracy of Thai Character
              recognition despite Thai language challenge, in which each
              character can combine up to 4 levels and its pattern is also quite
              complex.
              <br />
              <br />
              Eikonnex OCR family suite is categorized into two main categories
              —{' '}
              <span className={styles.primaryColorText}>
                Scene Text Reader
              </span>{' '}
              and{' '}
              <span className={styles.primaryColorText}>Document Reader</span>
            </>
          }
        />
      </div>
      <div className={styles.sceneTextReader}>
        <PageHeader title="Scene Text Reader" />
        <Article
          title={<></>}
          divider={false}
          spacing={15}
          body={
            <>
              While recognizing document text has its own complexity,
              recognizing scene text has been quite challenging. Due to our
              unique technology, Eikonnex OCR can solve major industry
              challenges, especially scene text recognition because scene images
              are more diverse in illumination, background, perspective error,
              and distortion than that of scanned documents.
              <br />
              <br />
              Scene text reader can be applied to so many business cases, for
              example reading ID card, license plate, asset tracking number and
              many more.
            </>
          }
        />
      </div>
      <div className={styles.idCardReader}>
        <DoubleGridRow
          leftChild={
            <Article
              title={
                <div className={styles.solutionsTitle}>Thai ID Card Reader</div>
              }
              divider={false}
              spacing={15}
              body={
                <>
                  Our{' '}
                  <span className={styles.primaryColorText}>
                    Eikonnex OCR ID
                  </span>{' '}
                  is the Thai ID card reader with the following highlight
                  features.
                  <ul>
                    <li>High accuracy of reading Thai ID card data fields</li>
                    <li>
                      Real-time identification verification of the cardholder
                      with face recognition technology
                    </li>
                    <li>REST API available for system integration</li>
                  </ul>
                </>
              }
            />
          }
          rightChild={
            <>
              <div className={styles.idCardReaderImages}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/combined-user-card-info.png`}
                  alt="id card reader infographic"
                />
              </div>
            </>
          }
        />
      </div>
      <div className={styles.assetTrackingNumberReader}>
        <DoubleGridRow
          reverse
          leftChild={
            <div className={styles.assetTrackingNumberReaderImages}>
              <img
                src={`${process.env.PUBLIC_URL}/images/asset-tracking-number-reader-1.png`}
                alt="asset tracking number OCR result example"
              />
              <img
                src={`${process.env.PUBLIC_URL}/images/asset-tracking-number-reader-2.png`}
                alt="asset tracking number OCR result example"
              />
            </div>
          }
          rightChild={
            <Article
              title={
                <div className={styles.solutionsTitle}>
                  Asset Tracking Number Reader
                </div>
              }
              divider={false}
              spacing={15}
              body={
                <>
                  Eikonnex OCR Scene Text Reader can be applied to read asset
                  numbers from video and images to support asset tracking
                  process automation.{' '}
                </>
              }
            />
          }
        />
      </div>
      <div className={styles.documentReader}>
        <PageHeader title="Document Reader" />
        <Article
          title={<></>}
          divider={false}
          spacing={15}
          body={
            <>
              Reading document text requires different text reader capabilities
              from scene text reader. Our{' '}
              <span className={styles.primaryColorText}>
                Eikonnex OCR Document
              </span>{' '}
              is another type of text reader specialized in reading document
              text and can be{' '}
              <span className={styles.secondaryColorText}>
                customized for various types of business forms and documents.
              </span>
            </>
          }
        />
      </div>
      <div className={styles.bankStatementReader}>
        <Article
          title={
            <div className={styles.solutionsTitle}>Bank Statement Reader</div>
          }
          divider={false}
          spacing={15}
          body={
            <>
              <span className={styles.primaryColorText}>
                Eikonnex OCR Statement
              </span>{' '}
              is our Eikonnex OCR Document customized for reading bank
              statements with semantic matching, translation, data validation
              and auto-correction to yield the best possible read data accuracy
              and fit bank statement context. Our Eikonnex OCR Statement has
              been successfully deployed and used in a real banking production
              environment.
            </>
          }
        />
      </div>
      <div className={styles.ourServices}>
        <PageHeader title="Our Services" />
        <div className={styles.customizableTextReader}>
          <Article
            title={
              <div className={styles.solutionsTitle}>
                Customizable Text Reader
              </div>
            }
            divider={false}
            spacing={15}
            body={
              <>
                While the ability to read and extract texts accurately from
                images is one of the most critical functionality of Eikonnex
                OCR, the read texts can be meaningfully and effectively used in
                business cases by applying business context meaning to the
                texts.
                <br />
                <br />
                Eikonnex OCR has been designed with a customizable business
                context plug-in, which is capable of not only incorporating
                business context fields to the read texts, but also performing
                data processing and auto data correction based on specific
                business context as required. Therefore, it is easy to apply
                Eikonnex OCR to business cases in various business domain
                contexts.
              </>
            }
          />
        </div>
        <div className={styles.digitalTransformationService}>
          <Article
            title={
              <div className={styles.solutionsTitle}>
                Digital Transformation Service
              </div>
            }
            divider={false}
            spacing={15}
            body={
              <>
                Eikonnex team consists of members who have strong experience in
                providing business software solution services, especially for
                digital transformation solutions. Other than providing Eikonnex
                OCR as an API product and service, we also work closely with
                customers to provide services in digital transformation turnkey
                solutions that leverage Eikonnex OCR as the text reader engine
                of the solutions.
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export const solutionsPath = '/thai-ocr-solutions';

export default Solutions;
