import { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home, { homePath } from './pages/Home';
import AboutUs, { aboutUsPath } from './pages/AboutUs';
import Solutions, { solutionsPath } from './pages/Solutions';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import styles from './app.module.css';

const App = () => {
  const contactRef = useRef<HTMLDivElement>(null);

  return (
    <Router>
      <ScrollToTop />
      <div className={styles.app}>
        <Navbar contactRef={contactRef} />
        <div className={styles.fillBody}>
          <Routes>
            <Route path={homePath} element={<Home />} />
            <Route path={aboutUsPath} element={<AboutUs />} />
            <Route path={solutionsPath} element={<Solutions />} />
          </Routes>
        </div>
        <Footer contactRef={contactRef} />
      </div>
    </Router>
  );
};

export default App;
