import styles from './styles.module.css';

export interface HighlightCardProps {
  title: String | React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
}

const HighlightCard = ({ title, body, footer }: HighlightCardProps) => {
  return (
    <div className={styles.highlightCardPadding}>
      <div className={styles.highlightCard}>
        <div className={styles.highlightCardHeader}>{title}</div>
        <div className={styles.lowerPart}>
          <div className={styles.body}>{body}</div>
          <div className={styles.footer}>{footer}</div>
        </div>
      </div>
    </div>
  );
};

export default HighlightCard;
