import { HiOutlineMail } from 'react-icons/hi';
// import { BsTelephone } from 'react-icons/bs';

import Article from '../Article';
import styles from './styles.module.css';

export interface FooterProps {
  contactRef: React.RefObject<HTMLDivElement>;
}

const Footer = ({ contactRef }: FooterProps) => {
  return (
    <div className={styles.footer}>
      <div className={styles.leftGroup}>
        <div className={styles.license}>
          {'© 2021-2023 Eikonnex AI Co., Ltd.'}
        </div>
      </div>
      <div className={styles.rightGroup}>
        <div className={styles.contact} ref={contactRef}>
          <Article
            textColor="light"
            divider={false}
            title={<div className={styles.contactTitle}>Contact</div>}
            body={
              <div className={styles.contactInfo}>
                <div className={styles.divider} />
                <div className={styles.contactInfoItem}>
                  <HiOutlineMail /> contact@eikonnex.ai
                </div>
                {/* <div className={styles.contactInfoItem}>
                  <BsTelephone /> ……
                </div> */}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
