import Article from '../../components/Article';
import DoubleGridRow from '../../components/DoubleGridRow';
import Profile from '../../components/Profile';
import PageHeader from '../../components/PageHeader';
import HalfEllipse from '../../images/half-ellipse.png';
import DoubleCircle from '../../images/double-circle.png';
import styles from './styles.module.css';

const AboutUs = () => {
  return (
    <div className={styles.aboutUs}>
      <div className={styles.aboutEikonnex}>
        <PageHeader title="About Us" />
        <Article
          title={<></>}
          divider={false}
          spacing={15}
          body={
            <>
              Eikonnex AI is an innovation-driven, Vision AI Deep Tech company.
              The company specializes in deep learning text reader solutions in
              both Thai and English symbol text characters in document images,
              scene images and videos. Our text reader products and services can
              be applied to various business industries including BFSI (banking,
              financial services and insurance), logistics and transportation ,
              retails and others, which seeks to implement RPA in their business
              operations to reduce operating costs and increase competitive
              advantages in business.
            </>
          }
        />
      </div>
      <div className={styles.visionMission}>
        <DoubleGridRow
          leftChild={
            <Article
              cappedWidth
              title="Mission"
              body={
                <>
                  To be the{' '}
                  <span className={styles.coloredText}>
                    English and Thai OCR text reader solution
                  </span>{' '}
                  that creates positive impact and helps our customers become
                  successful in{' '}
                  <span className={styles.coloredText}>process automation</span>{' '}
                  and{' '}
                  <span className={styles.coloredText}>
                    digital transformation
                  </span>
                  .
                </>
              }
            />
          }
          rightChild={
            <div className={styles.doubleCircleContainer}>
              <img src={DoubleCircle} alt="" />
            </div>
          }
        />
        <img className={styles.halfEllipse} src={HalfEllipse} alt="" />
      </div>
      <div className={styles.ourTeam}>
        <DoubleGridRow
          reverse
          leftChild={
            <img
              className={styles.ourTeamPicture}
              src={`${process.env.PUBLIC_URL}/images/our-team.jpg`}
              alt="our team members"
            />
          }
          rightChild={
            <Article
              cappedWidth
              title="Our Team"
              body={
                <>
                  Our core team consists of members with high quality and
                  multidisciplinary skills, knowledge and experience, which
                  blends innovative technology and practical industry sides
                  together to develop and offer vision AI products that can be
                  used in real-world scenarios. We are capable of providing not
                  only high quality products, but also end-to-end, full stack
                  vision AI text reader solutions and services.
                </>
              }
            />
          }
        />
      </div>
      <div className={styles.coFounders}>
        <Article
          title="Co-founders"
          body={
            <>
              <DoubleGridRow
                leftChild={
                  <Profile
                    imageSource={`${process.env.PUBLIC_URL}/images/profile-chinnavit.png`}
                    name="Chinnavit Chalidabhongse"
                    position="CEO & CTO"
                    content={
                      <>
                        Mr. Chalidabhongse has over 25 years of domestic and
                        overseas experience in IT professional consulting,
                        software development and management in the areas of
                        enterprise software architecture design and development,
                        business intelligence, data analysis, data warehouse,
                        performance management, budgeting, costing, search
                        engine, categorization, workflow and electronic document
                        management. He also has several years of teaching
                        experience at universities and professional levels in
                        the areas of data science, computer science and IT.
                        Currently, areas of his interest include data science,
                        big data, machine learning, data visualization and
                        business intelligence.
                        <br />
                        <br />
                        Chinnavit has master’s degrees in multidisciplinary
                        areas, which includes master’s degree in Computer
                        Science from University of Southern California, MBA
                        specialized in Finance from Johns Hopkins University and
                        master’s degree in Data Science from Monash University.
                        He obtained his bachelor’s degree in Computer
                        Engineering from Chulalongkorn University.
                      </>
                    }
                  />
                }
                rightChild={
                  <Profile
                    imageSource={`${process.env.PUBLIC_URL}/images/profile-thanarat.png`}
                    name="Thanarat Chalidabhongse"
                    nameTrailing="(Ph.D.)"
                    position="Senior Advisor"
                    content={
                      <>
                        Dr. Chalidabhongse is an Associate Professor at the
                        Department of Computer Engineering at Chulalongkorn
                        University and Head of Chula PIC Lab, where she has done
                        many researches in her expertise including image and
                        video processing, human motion understanding, object
                        detection and recognition, scene text analysis, medical
                        imaging, and real-time vision. Her statistical
                        background subtraction method has been one of the highly
                        regarded methods in the global computer vision research
                        community. She has applied her research and expertises
                        to many real-world business cases and applications.
                        <br />
                        <br />
                        Thanarat received her Ph.D. from the Computer Vision
                        Laboratory (CVL) at the University of Maryland under the
                        supervision of Professor Larry S. Davis, a world
                        renowned researcher in computer vision and machine
                        perception. She has a master’s degree with academic
                        excellence award in Computer Science (Neural Networks
                        Lab) from the University of Southern California, and a
                        bachelor’s degree in Computer Engineering from
                        Chulalongkorn University.
                      </>
                    }
                  />
                }
              />
              <div className={styles.coFoundersSpacing} />
              <DoubleGridRow
                leftChild={
                  <Profile
                    imageSource={`${process.env.PUBLIC_URL}/images/profile-thananop.png`}
                    name="Thananop Kobchaisawat"
                    nameTrailing="(Ph.D.)"
                    position="Chief AI Officer"
                    content={
                      <>
                        Dr. Kobchaisawat works as a full-time Chief AI Officer
                        at Eikonnex.ai. He has experience in both research and
                        real-world problems, primarily in the fields of image
                        processing, computer vision and deep learning. His main
                        interests focus on object detection, scene text
                        localization and recognition, text extraction, semantic
                        parsing, and deep learning application deployment in the
                        real-world.
                      </>
                    }
                  />
                }
                rightChild={
                  <Profile
                    imageSource={`${process.env.PUBLIC_URL}/images/profile-pravee.png`}
                    name="Pravee Kruachottikul"
                    nameTrailing="(Ph.D.)"
                    position="Chief Sales Officer"
                    content={
                      <>
                        Pravee Kruachottikul graduated in Ph.D. in the
                        Technopreneurship and Innovation Management Programme,
                        Graduate School, from Chulalongkorn University. His
                        professional area is a system integrator and innovation
                        developer. He has an extensive background and work
                        experience of more than 15 years in management within
                        tech firms and deep-tech innovation incubators. His
                        areas of research interests include innovation
                        development and applied digital and deep-tech research
                        (i.e. AI, Data Science, Robotics, Automation, or IoT)
                        for business applications such as health, medical,
                        manufacturing, construction, etc.
                      </>
                    }
                  />
                }
              />
            </>
          }
        />
      </div>
    </div>
  );
};

export const aboutUsPath = '/about-us';

export default AboutUs;
