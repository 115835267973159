import styles from './styles.module.css';

export interface DoubleGridRowProps {
  leftChild: React.ReactNode;
  rightChild: React.ReactNode;
  reverse?: boolean;
}

const DoubleGridRow = ({
  leftChild,
  rightChild,
  reverse = false,
}: DoubleGridRowProps) => {
  return (
    <div
      className={[
        styles.doubleGridRow,
        reverse ? styles.reverse : undefined,
      ].join(' ')}
    >
      <div className={styles.leftGroup}>{leftChild}</div>
      <div className={styles.rightGroup}>{rightChild}</div>
    </div>
  );
};

export default DoubleGridRow;
