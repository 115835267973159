import HighlightedText from '../HighlightedText';
import styles from './styles.module.css';

export interface PageHeaderProps {
  title: string;
}

const PageHeader = ({ title }: PageHeaderProps) => {
  return (
    <div className={styles.pageHeader}>
      <div className={styles.title}>
        <HighlightedText children={title} color="secondary" />
      </div>
      <div className={styles.divider} />
    </div>
  );
};

export default PageHeader;
