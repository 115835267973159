import styles from './styles.module.css';

export interface highlightedTextProps {
  children: string;
  position?: 'start' | 'end';
  color?: 'primary' | 'secondary';
  sizeMultiplier?: number;
  bottom?: number;
}

const HighlightedText = ({
  children,
  position = 'end',
  color = 'primary',
  sizeMultiplier = 1,
  bottom = -5,
}: highlightedTextProps) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.child}>
        <div
          className={[styles.highlight, styles[position], styles[color]].join(
            ' '
          )}
          style={{
            width: `${30 * sizeMultiplier}px`,
            height: `${10 * sizeMultiplier}px`,
            bottom: `${bottom}px`,
          }}
        />
        <span className={styles.text}>{children}</span>
      </span>
    </div>
  );
};

export default HighlightedText;
