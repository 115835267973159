import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { homePath } from '../../pages/Home';
import { aboutUsPath } from '../../pages/AboutUs';
import { solutionsPath } from '../../pages/Solutions';
import HamburgerIcon from '../../icons/hamburger-button.svg';
import HighlightedText from '../HighlightedText';
import styles from './styles.module.css';

export interface NavbarProps {
  contactRef: React.RefObject<HTMLDivElement>;
}

const Navbar = ({ contactRef }: NavbarProps) => {
  const useMobileComponent = useMediaQuery({ query: '(max-width: 992px)' });

  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  const location = useLocation();
  useEffect(() => {
    setShowMobileMenu(false);
  }, [location]);

  const scrollToContact = () => {
    if (contactRef.current !== null) {
      contactRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  if (useMobileComponent)
    return (
      <div className={styles.navbarMobile}>
        <div className={styles.leftGroup}>
          <img
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className={styles.hamburgerButton}
            src={HamburgerIcon}
            alt="button icon"
          />
        </div>
        <div className={styles.centerGroup}>
          <div className={styles.logo}>
            <img
              src={process.env.PUBLIC_URL + '/eikonnex-logo.png'}
              alt="eikonnex logo"
            />
          </div>
        </div>
        <div className={styles.rightGroup}></div>
        {showMobileMenu && (
          <div className={styles.mobileMenu}>
            <div className={styles.mobileMenuItem}>
              <Link to={homePath}>Home</Link>
            </div>
            <div className={styles.mobileMenuItem}>
              <Link to={aboutUsPath}>About Us</Link>
            </div>
            <div className={styles.mobileMenuItem}>
              <Link to={solutionsPath}>Solutions</Link>
            </div>
          </div>
        )}
      </div>
    );

  return (
    <div className={styles.navbar}>
      <div className={styles.leftGroup}>
        <Link to={homePath}>
          <div className={styles.logo}>
            <img
              src={process.env.PUBLIC_URL + '/eikonnex-logo.png'}
              alt="eikonnex logo"
            />
          </div>
        </Link>
        <div className={styles.navItem}>
          <Link to={aboutUsPath}>About Us</Link>
        </div>
        <div className={styles.navItem}>
          <Link to={solutionsPath}>Solutions</Link>
        </div>
      </div>
      <div className={styles.rightGroup}>
        <div className={styles.navItem} onClick={scrollToContact}>
          <HighlightedText position="end" color="primary">
            Contact us
          </HighlightedText>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
