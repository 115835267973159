import styles from './styles.module.css';

export interface ArticleProps {
  title: string | React.ReactNode;
  divider?: boolean;
  body: string | React.ReactNode;
  textColor?: 'light' | 'dark';
  spacing?: number;
  cappedWidth?: boolean;
}

const Article = ({
  title,
  divider = true,
  body,
  textColor = 'dark',
  spacing = 0,
  cappedWidth = false,
}: ArticleProps) => {
  return (
    <div
      className={[
        styles.article,
        styles[textColor],
        cappedWidth ? styles.cappedWidth : undefined,
      ].join(' ')}
    >
      <div className={styles.title}>{title}</div>
      {divider && <div className={styles.divider} />}
      {Boolean(spacing) && (
        <div className={styles.spacing} style={{ height: `${spacing}px` }} />
      )}
      <div className={styles.body}>{body}</div>
    </div>
  );
};

export default Article;
