import styles from './styles.module.css';

export interface ProfileProps {
  imageSource?: string;
  name?: string;
  nameTrailing?: string;
  position?: string;
  content?: string | React.ReactNode;
}

const Profile = ({
  imageSource,
  name,
  nameTrailing,
  position,
  content,
}: ProfileProps) => {
  return (
    <div className={styles.profile}>
      <div className={styles.titleRow}>
        {imageSource && (
          <img
            className={styles.profilePicture}
            src={imageSource}
            alt={`${name} profile`}
          />
        )}
        <div className={styles.titleText}>
          {(name || nameTrailing) && (
            <div className={styles.name}>
              {name} <span className={styles.nameTrailing}>{nameTrailing}</span>
            </div>
          )}
          <div className={styles.position}>{position}</div>
        </div>
      </div>
      <div className={styles.content}>{content}</div>
    </div>
  );
};

export default Profile;
